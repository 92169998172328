import React, { useState } from 'react';
import criticalImgSrc from '../../assets/error.png';
import warningImgSrc from '../../assets/advertencia.png';

function GlobalStatus({ data }) {
    const [visibleProjects, setVisibleProjects] = useState(null);
    const [activeProject, setActiveProject] = useState(null);

    const onActiveProjectChange = (projectName) => {
        setActiveProject(projectName);
    };

    const projectsByLevel = (level) =>
        Array.from(
            new Set(
                data
                    .filter(item => item.nivel === level)
                    .map(item => item.proyecto)
            )
        );

    const warningProjects = projectsByLevel(1);
    const criticalProjects = projectsByLevel(2);

    const StatusSection = ({ status, imgSrc, projects }) => {
        if (projects.length === 0) {
            return null;
        }

        return (
            <div className="flex mb-5 items-center space-x-4" style={{ width: '50%' }}>
                <div className="flex-1 flex justify-center">
                    <img src={imgSrc} alt={status} className="max-w-xs max-h-xs" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                </div>
                <div className="flex-1 flex justify-start">
                    <ul className="list-none p-0">
                        {projects.map((proyecto, index) => (
                            <li key={index} className="text-lg text-gray-700">
                                <button onClick={() => onActiveProjectChange(proyecto)}>
                                    {proyecto}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    };

    return (
        <>
            <StatusSection
                status="Warning"
                imgSrc={warningImgSrc}
                projects={warningProjects}
            />
            <StatusSection
                status="Critical"
                imgSrc={criticalImgSrc}
                projects={criticalProjects}
            />
        </>
    );
}

export default GlobalStatus;