import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, TextField, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

function Configuration() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [minutes, setMinutes] = useState(3); 

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSave = () => {
        localStorage.setItem('updateInterval', minutes);

        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <SettingsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <TextField
                        label="Minutos"
                        value={minutes}
                        onChange={(e) => setMinutes(e.target.value)}
                    />
                    <Button onClick={handleSave}>Guardar</Button>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default Configuration;
