import { Container, Box, CssBaseline } from '@mui/material';
import StatusHome from '../src/components/StatusHome/index.jsx';
import HeaderStatus from '../src/layout/Header/index.jsx';

export default function App() {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />

      <HeaderStatus />

      <Container style={{ marginTop: 20, height: '100vh', maxWidth: '1367.28px' }}>
        <Box py={3} minHeight={380}>
          <StatusHome />
        </Box>
      </Container>

    </Box>
  );
}
