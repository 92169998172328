import React, { useState, useEffect, useRef } from 'react';
import {  Snackbar } from '@mui/material';
import ProyectoEnum from '../../utils/ProyectoEnum.js';
import StatusCard from '../StatusCards/index.jsx';
import './index.css';

function StatusContainerCards({ data, onProblemResolved, updateData }) {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const prevDataRef = useRef(data);

    useEffect(() => {
        const wasLevel1 = prevDataRef.current.some(item => item.nivel === 1);
        const isNowLevel2 = data.some(item => item.nivel === 2);

        if (wasLevel1 && isNowLevel2) {
            setOpenSnackbar(false);
        }

        prevDataRef.current = data;
    }, [data]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const groupedData = data.reduce((acc, item) => {

        const key = ProyectoEnum[item.proyecto] || item.proyecto;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    const sortedProjects = Object.entries(groupedData).sort((a, b) => {
        const [projectA, itemsA] = a;
        const [projectB, itemsB] = b;

        const allLevel2A = itemsA.every(item => item.nivel === 2);
        const allLevel2B = itemsB.every(item => item.nivel === 2);

        if (allLevel2A && !allLevel2B) return -1;
        if (!allLevel2A && allLevel2B) return 1;
        return 0;
    });

    return (
        <div className="space-y-8">
            {sortedProjects.map(([proyecto, items]) => {
                if (items.length === 0) return null;

                const allLevel2 = items.every(item => item.nivel === 2);

                return (
                    <div key={proyecto} className="animate-fade-in-down">
                        <h4 className={`text-2xl font-bold text-gray-800 mb-2`}> 
                            {proyecto} ({items.length})
                        </h4>
                        <hr className="border-gray-300 mb-4" />
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {items.map(item => (
                                <div key={item.id} className="col-span-1">
                                    <StatusCard data={item} onProblemResolved={onProblemResolved} updateData={updateData} />
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message="El nivel de alerta ha cambiado a 2!"
            />
        </div>
    );
}

export default StatusContainerCards;
