import { AppBar, Toolbar, Typography } from '@mui/material';
import './index.css';

import Logo from '../../assets/rapiboy.svg';
import Configuration from '../../components/Configuaration/index'

function HeaderStatus() {
    return (
        <>
            <AppBar position="static" className="Header">
                <Toolbar>
                    <img
                        src={Logo}
                        width="100"
                        height="100"
                        alt="Logo"
                        className="Logo"
                    />
                    <Typography variant="h6">
                        Rapi Status
                    </Typography>
                    <Configuration />
                </Toolbar>
            </AppBar>
        </>
    );
}

export default HeaderStatus;
