import React, { useState } from 'react';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Alert } from '@mui/lab';

function StatusItem({ data, updateData, onProblemResolved }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const calculateTimeDifference = (dateString) => {
    const itemDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - itemDate;
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    return differenceInMinutes;
  };

  const handleResuelto = async (id) => {
    try {
      const response = await axios.put(`https://status.rapiboy.com/MarcarResuelto?Id=${id}`);

      if (response.status === 200) {
        updateData();
        onProblemResolved();
      } else {
        console.error('Error in response:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const rowBgColor = data.nivel === 2 ? 'bg-red-100' : data.nivel === 1 ? 'bg-yellow-100' : 'bg-green-100';

  return (
    <>
      <tr className={`${rowBgColor} border-b w-full`}>
        <td className="p-2">{data.id}</td>
        <td className="p-2">{calculateTimeDifference(data.fecha)} minutos</td>
        <td className="p-2">{data.proyecto}</td>
        <td className="p-2 text-left">{data.descripcion}</td>
        <td className="p-2 text-center">
          <button onClick={() => handleResuelto(data.id)} title='Resolver' className="text-green-500 hover:text-green-700">
            <CheckCircleOutlineIcon />
          </button>
          {/* <button className="text-gray-500 hover:text-gray-700">
            <MoreHorizIcon />
          </button> */}
        </td>
      </tr>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          ¡Se ha resuelto el problema!
        </Alert>
      </Snackbar>
    </>
  );
}

export default StatusItem;


