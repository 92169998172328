import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Spinner from '../Spiner/index.jsx';
import './index.css'
import StatusContainerItems from '../StatusContainerItems/index.jsx'
import StatusContainerCards from '../StatusContainerCards/index.jsx';
import GlobalStatus from '../GlobalStatus/index.jsx';
import NoBugs from '../../assets/no-bugs.png'

function StatusContainer({ view, onProblemResolved, searchValue, ...otherProps }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updateInterval, setUpdateInterval] = useState(null);
    const accordionRefs = useRef({});
    const [activeProject, setActiveProject] = useState(null);

    const getStatus = async () => {
        try {
            const response = await axios.get(`https://status.rapiboy.com/GetList`);
            return response.data;
        } catch (error) {
            console.error('API error:', error);
            throw error;
        }
    };

    const fetchData = async () => {
        try {
            const result = await getStatus();
            console.log('Datos obtenidos de la API:', result);
            setData(result);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const storedInterval = localStorage.getItem('updateInterval');

        if (storedInterval) {
            setUpdateInterval(Number(storedInterval));
        }

        fetchData();
        const intervalId = storedInterval ? setInterval(fetchData, Number(storedInterval) * 60 * 1000) : null;

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const scrollToProject = (projectName) => {
        const projectRef = accordionRefs.current[projectName];
        if (projectRef) {
            projectRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleActiveProjectChange = (projectName) => {
        setActiveProject(projectName);
    };

    if (loading) {
        return <Spinner isLoading={true} />;
    }

    if (!data || data.length == 0) {
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <img src={NoBugs} alt="No Errors" className="w-16 h-16" />
                <div className="text-lg text-gray-700">
                    Sin Errores
                </div>
                <p className="mt-2 text-sm text-gray-500">
                    No hay información para mostrar en este momento.
                </p>
            </div>
        )
    }

    const filteredData = data.filter(item => {
        if (searchValue) {
            return item.descripcion.toLowerCase().includes(searchValue.toLowerCase());
        }
        return true;
    });

    return (
        <>
            <GlobalStatus data={data} onProjectClick={scrollToProject} onActiveProjectChange={handleActiveProjectChange} />
            {view === 'table' ?
                <StatusContainerItems data={filteredData} activeProject={activeProject} onProblemResolved={onProblemResolved} updateData={fetchData} /> :
                <StatusContainerCards data={filteredData} activeProject={activeProject} onProblemResolved={onProblemResolved} updateData={fetchData} />
            }
        </>
    );

}

export default StatusContainer;