import { useEffect } from 'react';
import StatusContainer from '../StatusContainer/index';
import SearchBar from '../SearchBar/index'
import { useState } from 'react';
import { notification } from 'antd';
import axios from 'axios';

function StatusHome() {
    const [searchValue, setSearchValue] = useState('');
    const [view, setView] = useState('table');
    const [showNotification, setShowNotification] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getStatus = async () => {
        try {
            const response = await axios.get(`https://status.rapiboy.com/GetList`);
            return response.data;
        } catch (error) {
            console.error('API error:', error);
            throw error;
        }
    };

    const fetchData = async () => {
        try {
            const result = await getStatus();
            console.log('Datos obtenidos de la API:', result);
            setData(result);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const showSuccessNotification = () => {
        notification.success({
            message: '¡Éxito!',
            description: 'El problema ha sido marcado como resuelto.',
            duration: 1000,
        });
    };

    useEffect(() => {
        if (showNotification) {
            notification.success({
                message: '¡Éxito!',
                description: 'Se completó el error.',
                onClose: () => setShowNotification(false)
            });
        }
    }, [showNotification]);

    return (
        <>
            <SearchBar
                onSearch={setSearchValue}
                onViewChange={setView}
                updateData={fetchData}
            />
            <StatusContainer
                endpoint="../../api/getStatus"
                searchValue={searchValue}
                view={view}
                onProblemResolved={showSuccessNotification}
            />
        </>
    );
}

export default StatusHome;