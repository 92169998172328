import React, { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import { Check as CheckIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import axios from 'axios';

function StatusCard({ data, updateData, onProblemResolved }) {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const calculateTimeDifference = (dateString) => {
        const itemDate = new Date(dateString);
        const currentDate = new Date();
        const differenceInMilliseconds = currentDate - itemDate;
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        return differenceInMinutes;
    };

    const cardColorClass = (nivel) => {
        switch (nivel) {
            case 2: return "border-red-700 bg-red-100"; // Nivel crítico
            case 1: return "border-yellow-400 bg-yellow-100"; // Nivel advertencia
            default: return "border-gray-200"; // Nivel normal
        }
    };

    const handleResuelto = async (id) => {
        try {
            const response = await axios.put(`https://status.rapiboy.com/MarcarResuelto?Id=${id}`);
    
            if (response.status === 200) {
                updateData();
                onProblemResolved();
                setOpenSnackbar(true); 
            } else {
                console.error('Error in response:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <>
            <div className={`border-l-4 p-4 mb-5 rounded shadow ${cardColorClass(data.nivel)}`}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="text-lg font-medium mr-3">{data.proyecto}</span>
                        <span className="text-sm">hace {calculateTimeDifference(data.fecha)} minutos</span>
                    </div>
                    <div>
                        <IconButton onClick={() => handleResuelto(data.id)} color="primary" size="small">
                            <CheckIcon />
                        </IconButton>
                        {/* <IconButton color="default" size="small">
                            <MoreHorizIcon />
                        </IconButton> */}
                    </div>
                </div>
                <div className="text-gray-700 mt-3">
                    {data.descripcion}
                </div>
            </div>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="success">
                    ¡Problema resuelto!
                </Alert>
            </Snackbar>
        </>
    );
}

export default StatusCard;
