import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';

function Spinner({ isLoading }) {
  return isLoading ? (
    <div className="spinnerContainer">
      <CircularProgress size={80} />
    </div>
  ) : null;
}

export default Spinner;