import React, { useState, useRef } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import Spinner from '../Spiner/index.jsx';
import StatusItem from '../StatusItem/index.jsx';
import ProyectoEnum from '../../utils/ProyectoEnum.js'
import NivelAlertEnum from '../../utils/NivelAlertaEnum.js'

function StatusContainerItems({ data, onProblemResolved, updateData }) {
    const [activeProject, setActiveProject] = useState(null);
    const accordionRefs = useRef({});
    const [open, setOpen] = useState({});

    const groupedData = data.reduce((acc, item) => {

        const key = ProyectoEnum[item.proyecto] || item.proyecto;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    const handleToggle = (proyecto) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [proyecto]: !prevOpen[proyecto]
        }));
    };

    const isOpen = (proyecto) => !!open[proyecto];

    if (!data) {
        return <Spinner isLoading={true} />;
    }

    return (
        <div className="flex flex-wrap gap-1 w-full">
            {Object.entries(groupedData).map(([proyecto, items]) => {
                if (activeProject && activeProject !== proyecto) return null;
                if (items.length === 0) return null;
                const criticalItems = items.filter(item => item.nivel === NivelAlertEnum.CRITICAL).length;
                const warningItems = items.filter(item => item.nivel === NivelAlertEnum.WARNING).length;

                const isCritical = criticalItems > items.length * 0.5 || criticalItems > 0;
                const isWarning = !isCritical && (warningItems > items.length * 0.5 || warningItems > 0);

                const headerClass = isCritical
                    ? 'bg-red-500'
                    : isWarning
                        ? 'bg-yellow-500'
                        : 'bg-green-500';

                return (
                    <div ref={el => accordionRefs.current[proyecto] = el} key={proyecto} className="w-full mb-2">
                        <div className={`flex justify-between items-center p-4 rounded-lg ${headerClass}`} onClick={() => handleToggle(proyecto)}>
                            <div className="flex flex-1 items-center">
                                <span className="text-white font-bold mr-4">{proyecto}</span>
                                <span className="text-white">{items.length}</span>
                            </div>
                            {isOpen(proyecto)
                                ? <ChevronUpIcon className="h-6 w-6 text-white" />
                                : <ChevronDownIcon className="h-6 w-6 text-white" />}
                        </div>
                        <div className={`${isOpen(proyecto) ? 'block' : 'hidden'} overflow-auto w-full`}>
                            <table className="w-full rounded-lg">
                                <thead className={`bg-gray-800 rounded-lg`}>
                                    <tr>
                                        <th className="text-white px-4 py-2">ID</th>
                                        <th className="text-white px-4 py-2">Minutos</th>
                                        <th className="text-white px-4 py-2">Proyecto</th>
                                        <th className="text-white px-4 py-2">Descripción</th>
                                        <th className="text-white px-4 py-2 text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => (
                                        <StatusItem key={item.id} data={item} onProblemResolved={onProblemResolved} updateData={updateData} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default StatusContainerItems;
