import React, { useState } from 'react';
import axios from 'axios';
import { Snackbar } from '@mui/material';

const SearchBar = ({ onSearch, onViewChange, updateData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch(value);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleAllResult = async () => {
        try {
            const response = await axios.put(`https://status.rapiboy.com/MarcarResueltoTodas`);

            if (response.status === 200) {
                updateData();
                setSnackbarOpen(true);
            } else {
                console.error('Error in response:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="flex items-center space-x-4 mb-4">
            <div className="flex-auto">
                <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => onViewChange('table')}
                    className="px-4 py-2 mr-2 text-sm bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
                >
                    Tabla
                </button>
                <button
                    type="button"
                    onClick={() => onViewChange('cards')}
                    className="px-4 mr-2 py-2 text-sm bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
                >
                    Cards
                </button>
                <button
                    type="button"
                    onClick={() => handleAllResult()}
                    className="px-4 py-2 text-sm bg-yellow-400 rounded-md hover:bg-gray-300 focus:outline-none"
                >
                    Resolver Todos
                </button>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}
                message="¡Todos los resultados resueltos!"
            />
        </div>
    );
};

export default SearchBar;